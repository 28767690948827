.bg-mh-red-500 {
  @apply bg-red-500;
}
.sm\:bg-mh-red-500 {
  @apply sm:bg-red-500;
}
.wings-mh-red-500 {
  @apply wings-red-500;
}
.sm\:wings-mh-red-500 {
  @apply sm:wings-red-500;
}

.bg-mh-sand-100 {
  @apply bg-sand-100;
}
.sm\:bg-mh-sand-100 {
  @apply sm:bg-sand-100;
}
.wings-mh-sand-100 {
  @apply wings-sand-100;
}
.sm\:wings-mh-sand-100 {
  @apply sm:wings-sand-100;
}

.bg-mh-sand-200 {
  @apply bg-sand-200;
}
.sm\:bg-mh-sand-200 {
  @apply sm:bg-sand-200;
}
.wings-mh-sand-200 {
  @apply wings-sand-200;
}
.sm\:wings-mh-sand-200 {
  @apply sm:wings-sand-200;
}
